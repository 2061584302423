<template>
  <form
    class="form"
    :class="{ mobile: props.isMobile }"
    data-t="email-code-step"
    @submit.prevent="handleSubmit"
  >
    <div class="form-wrapper">
      <div class="form-header">
        <h2 v-if="!props.isMobile" class="title">
          {{ t('recoveryPassword.secondStepTitle') }}
        </h2>
        <p class="subtext">
          {{ t('recoveryPassword.secondStepSubTitle') }}
        </p>
        <span class="email">{{ props.userEmail }}</span>
      </div>
      <St2FA
        :is-code-valid="isCodeValid"
        :error="checkCodeError"
        :platform="props.isMobile ? 'mobile' : 'desktop'"
        @input-filled="inputFilled"
        @resend-code-clicked="handleRetry"
      />
    </div>

    <StButton
      v-if="!props.isMobile"
      :label="t('recoveryPassword.secondStepGoBack')"
      size="l"
      type="text-only"
      icon="arrow-left"
      is-left-icon
      @click="$emit('back')"
    />
  </form>
</template>

<script setup lang="ts">
import St2FA from '@st/core/components/St2Fa/St2Fa.vue'

const { t } = useI18n()

const props = defineProps<{
  userEmail: string
  isMobile?: boolean
}>()
const emit = defineEmits<{
  (e: 'success', value: string): void
  (e: 'back'): void
}>()

const code = ref('')
const attemptsLeft = ref(1)
const isCodeValid = ref(false)
const errorMessage = ref('')
const requestBody = computed(() => ({
  email: props.userEmail,
  code: code.value,
}))

const { execute: checkCode, error: checkCodeError } = useStFetch(
  '/password/recovery/code/check',
  {
    method: 'post',
    body: requestBody,
    immediate: false,
    watch: false,
  },
)

const TIME_OUT_FOR_SUCCESS_VIEW = 1000
async function handleSubmit() {
  await checkCode()

  if (!checkCodeError.value) {
    isCodeValid.value = true
    /*
      setTimeout для отображения success состояния
      StCodeInput перед сменой шага
    */
    setTimeout(() => {
      emit('success', code.value)
    }, TIME_OUT_FOR_SUCCESS_VIEW)
  }
}

function inputFilled(value: string) {
  code.value = value

  handleSubmit()
}

watch(
  () => code.value,
  () => {
    errorMessage.value = ''
  },
)

watch(
  () => checkCodeError.value,
  (error) => {
    const errorMesage = error?.data?.error
    switch (errorMesage) {
      case 'CONFIRMATION_CODE_NOT_VALID': {
        attemptsLeft.value = error?.data?.data.attemptsLeft ?? 0
        errorMessage.value = t('recoveryPassword.confirmationCodeError', {
          value: error?.data?.data.attemptsLeft,
        })
        break
      }
      case 'CONFIRMATION_CODE_MAX_ATTEMPTS_EXCEEDED': {
        errorMessage.value = t(
          'recoveryPassword.confirmationCodeMaxAttemptsError',
          {
            value: error?.data?.data.ttl,
          },
        )
        break
      }
      default: {
        errorMessage.value = ''
      }
    }
  },
)

const { getToken, recaptchaQueryParam } = useRecaptcha()

const { execute: sendCode, error: sendCodeError } = useStFetch(
  '/password/recovery/code/send',
  {
    method: 'post',
    body: {
      email: props.userEmail,
    },
    immediate: false,
    watch: false,
    query: recaptchaQueryParam,
  },
)
async function handleRetry() {
  code.value = ''
  await getToken('/password/recovery/code/send')
  await sendCode()

  if (sendCodeError.value) {
    errorMessage.value = t('recoveryPassword.codeResendTimeOut')
  }
}
</script>

<style scoped>
.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}

.subtext {
  margin: 0;
  margin-top: var(--spacing-100);
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-300);
}

.retry-code {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: var(--spacing-050);
    font: var(--desktop-text-xs-medium);
    color: var(--text-tertiary);
  }
}

.timeout {
  margin: 0;
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: center;
}

.recovery-code-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  width: 100%;

  &.mobile {
    padding: 0 var(--spacing-200);

    .subtext {
      margin-top: var(--spacing-200);
      font: var(--mobile-text-content-regular);
    }

    .form-wrapper {
      gap: var(--spacing-200);
    }

    .recovery-code-wrapper {
      margin-top: var(--spacing-100);
    }

    .retry-code {
      span {
        font: var(--mobile-text-content-regular);
      }
    }

    .timeout {
      font: var(--mobile-text-content-regular);
    }

    .email {
      font: var(--mobile-text-content-regular);
      color: var(--text-primary);
    }
  }
}
</style>
