<template>
  <form
    class="form"
    :class="{ mobile: props.isMobile }"
    data-t="new-password-step"
    @submit.prevent="handleSubmit"
  >
    <div class="form-wrapper">
      <div class="form-header">
        <h2 v-if="!props.isMobile" class="title">
          {{ t('recoveryPassword.thirdStepTitle') }}
        </h2>
        <p class="subtext">
          {{ t('recoveryPassword.thirdStepSubtext') }}
        </p>
      </div>

      <StInputPassword
        v-bind="password.componentBindings"
        data-t="new-password"
        :placeholder="t('recoveryPassword.passwordLabel')"
        :error="!!errorMessage || !!password.error"
        :error-message="errorMessage || password.error"
        :rules="passwordValidators"
        :size="props.isMobile ? 'm' : 'l'"
        :rules-position="props.isMobile ? 'top' : 'bottom'"
      />
      <StButton
        submit
        data-t="recovery-submit"
        :label="t('recoveryPassword.thirdStepButton')"
        :size="props.isMobile ? 'l' : 'xl'"
        :disabled="!isValid"
        class="submit-button"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { useUserStore } from '../../../stores/useUserStore'
import { usePasswordValidator } from '../../../composables/usePasswordValidator'

const { t } = useI18n()

const { tryToAuthenticate } = useUserStore()

const props = defineProps<{
  recoveryData: {
    email: string
    code: string
  }
  isMobile?: boolean
}>()

const emit = defineEmits<{
  (e: 'success'): void
}>()

const { passwordValidators, password, isValid } = usePasswordValidator()

const requestBody = computed(() => ({
  email: props.recoveryData.email,
  code: props.recoveryData.code,
  newPassword: password.value,
}))

const { execute: recoveryPassword, error: recoveryPasswordError } = useStFetch(
  '/password/recovery',
  {
    method: 'post',
    body: requestBody,
    immediate: false,
    watch: false,
  },
)

async function handleSubmit() {
  await recoveryPassword()
  if (!recoveryPasswordError.value) {
    await tryToAuthenticate()
    emit('success')
  }
}

const errorMessage = computed(() => {
  const errorBody = recoveryPasswordError.value?.data
  if (!errorBody) return undefined

  const { error: errorCode } = errorBody

  switch (errorCode) {
    case 'USER_PASSWORD_DUPLICATION': {
      return t('recoveryPassword.userPasswordDupicateError')
    }
    case 'USER_PASSWORD_REQUIREMENT_VIOLATION': {
      return t('recoveryPassword.userPasswordRequirementError')
    }
    case 'CONFIRMATION_CODE_NOT_VALID': {
      return t('recoveryPassword.conformationCodeNotValidError')
    }
    case 'CONFIRMATION_CODE_MAX_ATTEMPTS_EXCEEDED': {
      return t('recoveryPassword.conformationCodeAttemptsExceeded')
    }
    default: {
      return t('recoveryPassword.thirdStepTryLater')
    }
  }
})
</script>

<style scoped>
.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}

.subtext {
  margin: 0;
  margin-top: var(--spacing-100);
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-300);
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  &.mobile {
    padding: 0 var(--spacing-200);
    padding-bottom: var(--spacing-200);

    .subtext {
      margin-top: var(--spacing-200);
      font: var(--mobile-text-content-regular);
    }

    .form-wrapper {
      gap: var(--spacing-200);
    }

    .submit-button {
      margin-top: auto;
    }
  }
}
</style>
