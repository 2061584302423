<template>
  <form
    class="form"
    :class="{ mobile: props.isMobile }"
    data-t="email-step"
    @submit.prevent="handleSubmit"
  >
    <div class="form-wrapper">
      <div class="form-header">
        <h2 v-if="!props.isMobile" class="title">
          {{ t('recoveryPassword.firstStepTitle') }}
        </h2>
        <p class="subtext">{{ t('recoveryPassword.firstStepSubTitle') }}</p>
      </div>
      <StInput
        v-bind="email.componentBindings"
        :placeholder="t('recoveryPassword.emailLabel')"
        :error="!!errorMessage || !!email.error"
        :error-message="errorMessage || email.error"
        :size="props.isMobile ? 'm' : 'l'"
        type="email"
        data-t="email-recovery"
      />
      <StButton
        submit
        :label="t('recoveryPassword.firstStepButton')"
        :size="props.isMobile ? 'l' : 'xl'"
        :disabled="!isValid || isLoadingRecaptcha"
        data-t="first-step-button"
        :loading="requestStatus === 'pending'"
        class="submit-button"
      />
      <StButton
        :label="t('recoveryPassword.firstStepRestoreEmalAccess')"
        :size="props.isMobile ? 'm' : 's'"
        type="text-only"
        :to="supportEmailLink"
      />
    </div>
    <StButton
      v-if="!props.isMobile"
      :label="t('recoveryPassword.firstStepGoBack')"
      size="l"
      type="text-only"
      icon="arrow-left"
      is-left-icon
      replace
      :to="{ query: { modal: 'login' } }"
    />
  </form>
</template>

<script setup lang="ts">
import { useForm, required, validEmail } from '@st/validate'
import { useTimeoutTimer } from '@st/use/composables'

const { t } = useI18n()

const props = defineProps<{
  userEmail: string
  isMobile?: boolean
}>()
const emit = defineEmits<{
  (e: 'success', value: string): void
}>()

const { supportEmailLink } = useSupportUrl()

const {
  fields: { email },
  values,
  isValid,
} = useForm({
  fieldsSchema: {
    email: {
      initialValue: props.userEmail,
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
        {
          rule: validEmail,
          errorMessage: t('recoveryPassword.emailError'),
        },
      ],
      immediate: true,
    },
  },
})

const { getToken, isLoadingRecaptcha, recaptchaQueryParam } = useRecaptcha()

const {
  execute: sendCode,
  error: sendCodeError,
  status: requestStatus,
} = useStFetch('/password/recovery/code/send', {
  method: 'post',
  body: values,
  immediate: false,
  watch: false,
  query: recaptchaQueryParam,
})

async function handleSubmit() {
  await getToken('/password/recovery/code/send')
  await sendCode()

  if (!sendCodeError.value) emit('success', values.value.email)
}

const { startTimer, isFinished, timer } = useTimeoutTimer()

const errorMessage = computed(() => {
  const errorBody = sendCodeError.value?.data
  if (!errorBody) return undefined

  const { error: errorCode } = errorBody

  switch (errorCode) {
    case 'CONFIRMATION_CODE_RESEND_TIMEOUT': {
      return t('recoveryPassword.codeResendTimeOut', { value: timer })
    }
    case 'RECAPTCHA_IS_INVALID': {
      return t('recoveryPassword.wrongCaptcha')
    }
    case 'RECAPTCHA_REQUEST_ERROR': {
      return t('recoveryPassword.wrongCaptcha')
    }
    case 'VALIDATION_ERROR': {
      return t('recoveryPassword.emailError')
    }
    default: {
      return ''
    }
  }
})

watch(isFinished, () => {
  email.reset()
})

watch(
  () => sendCodeError.value,
  (err) => {
    if (!err) return
    const errorBody = err.data
    if (!errorBody) return

    const { error: errorCode } = errorBody

    if (errorCode === 'CONFIRMATION_CODE_RESEND_TIMEOUT') {
      startTimer(errorBody.data.ttl)
    }
  },
)
</script>

<style scoped>
.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}

.subtext {
  margin: 0;
  margin-top: var(--spacing-100);
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-300);
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  &.mobile {
    padding: 0 var(--spacing-200);
    padding-bottom: var(--spacing-200);

    .subtext {
      margin-top: var(--spacing-200);
      font: var(--mobile-text-content-regular);
    }

    .form-wrapper {
      gap: var(--spacing-200);
    }

    .submit-button {
      margin-top: auto;
    }
  }
}
</style>
