<template>
  <section
    class="recovery-form"
    :class="{ mobile: props.isMobile }"
    data-t="recovery-form"
  >
    <MFormHeader
      v-if="props.isMobile"
      :with-back-button="!isLast"
      class="header"
      data-t="tabs"
      @back="handleFormBack"
    >
      <p class="header-text">{{ headerText }}</p>
    </MFormHeader>
    <FirstStep
      v-if="isCurrent(0)"
      :user-email="props.email"
      :is-mobile="props.isMobile"
      @success="handleFirstStepSuccess"
    />
    <SecondStep
      v-if="isCurrent(1)"
      :user-email="recoveryData.email"
      :is-mobile="props.isMobile"
      @success="handleSecondStepSuccess"
      @back="goToPrevious"
    />
    <ThirdStep
      v-if="isCurrent(2)"
      :recovery-data="recoveryData"
      :is-mobile="props.isMobile"
      @success="handleThirdStepSuccess"
    />
  </section>
</template>

<script setup lang="ts">
import FirstStep from './parts/FirstStep.vue'
import SecondStep from './parts/SecondStep.vue'
import ThirdStep from './parts/ThirdStep.vue'
import MFormHeader from '../parts/MFormHeader.vue'

const props = withDefaults(
  defineProps<{
    email?: string
    isMobile?: boolean
  }>(),
  {
    email: '',
    isMobile: false,
  },
)

const { t } = useI18n()

const recoveryData = ref({
  email: props.email,
  code: '',
})
const emit = defineEmits<{
  (e: 'success'): void
  (e: 'stepChanged', value: number): void
}>()

const { isCurrent, goToNext, goToPrevious, current, isLast } = useStepper([
  0, 1, 2,
])

function handleFirstStepSuccess(email: string) {
  recoveryData.value.email = email
  goToNext()
  emit('stepChanged', current.value)
}
function handleSecondStepSuccess(code: string) {
  recoveryData.value.code = code
  goToNext()
  emit('stepChanged', current.value)
}

const router = useRouter()
function handleThirdStepSuccess() {
  router.replace({ query: {} })
  emit('success')
}

function handleFormBack() {
  if (current.value === 0) {
    router.replace({ query: { modal: 'login' } })
  } else goToPrevious()
}

const headerText = computed(() =>
  isLast.value
    ? t('recoveryPassword.thirdStepTitle')
    : t('recoveryPassword.firstStepTitle'),
)
</script>

<style scoped>
.recovery-form {
  display: flex;

  width: 480px;
  min-height: 444px;
  padding: var(--spacing-500) var(--spacing-600);

  background: var(--background-base);
  border-radius: var(--border-radius-150);

  &.mobile {
    flex-direction: column;

    width: 100%;
    height: 100%;
    padding: 0;

    border-radius: 0;
  }
}

.header-text {
  margin: 0;
  padding: var(--spacing-100) 0;
  font: var(--mobile-title-2-semibold);
}
</style>
